import { Radio, Switch, Tag, Typography, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  xboxActiveOrderedListFetch,
  xboxCancelOrder,
  xboxCheckCodes,
  xboxFetchOrderInfo,
  xboxFetchOrderInfoReset,
  xboxGetInventoryOrder,
  xboxNonManagerOrderedListFetch,
  xboxOrderedListFetch,
  xboxOrderedListFetchReset,
  xboxStopOrder,
  xboxStopOrderReset,
} from '../../Reducers/xbox';
import Filters from '../Orders/Filters';
import './styles.scss';
import TableOrdered from '../Orders/TableOrdered';
import UserSettingsService from '../../Services/UserSettingsService';
import { momentToUnixRange, dateRangePeriods } from '../../Utils/date';
import useTable from '../../Utils/useTable';
import { redirect, useNavigate, useOutletContext } from 'react-router-dom';
import Page404 from '../Page404';

/** This is Page */
function XboxOrders() {

  const isManager = useOutletContext();

  const [activeList, setActiveList] = useState("");

  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
  } = useTable(state => state.xbox[activeList], activeList === "ordered_list" ? xboxOrderedListFetch : activeList === "user_list" ? xboxNonManagerOrderedListFetch : activeList === "active_ordered_list" ? xboxActiveOrderedListFetch : null);

  
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState([]);

  const payload = useSelector(
    (state) => state.core.customer.id,
    shallowEqual
  );

  // const updateList = useCallback(
  //   () => fetch()
  //   [dispatch, fetch]
  // );

  useEffect(() => {
    if (isManager !== null) {
    if (isManager) {
      setActiveList("ordered_list")
    } else {
      setActiveList("user_list")
    }
    }
  }, [isManager])

  useEffect(() => {
      fetch();
  }, [payload]);

  const changeActive = useCallback(
    (e) => {
      e ? setActiveList('active_ordered_list') : setActiveList('ordered_list')
      fetch()
    },
    []
  );

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  return (
    <>
      <Typography.Title>Orders</Typography.Title>
      {
          !!selectedRow.length
          ? <span style={{ display: "flex", alignItems: "center", marginBottom: "10px"}}><b>
            <Tag>Total {
              selectedRow.reduce((previousValue, currentValue) => {
                const rowArr = currentValue.split('_')
                return (previousValue + +rowArr[1])
              }, 0).toFixed(2)
            } EUR {"("}{selectedRow.length} orders{")"}</Tag> 
          </b></span> : <span style={{ display: "flex", alignItems: "center", marginBottom: "10px"}}><b>
            <Tag>Total 0 EUR {"("}No orders selected{")"}</Tag> 
          </b></span>
        }
      {isManager && <div className='active_filter'>
        <Switch 
        checkedChildren="All customers"
        unCheckedChildren="See all customers"
        loading={isLoading} 
        onChange={changeActive}
        />
      </div>}
      <Filters
        className="mb"
        fetchList={fetch}
        changeActive={changeActive}
        activeList={activeList}
        isLoading={isLoading}
        setFiltersAndQuery={onFilter}
        value={filters}
        setFilters={(filters) =>
          UserSettingsService.setFilters('XBOXOrder', onFilter, filters)
        }
        isManager={isManager}
      />
      
      <TableOrdered
        fetch={fetch}
        list={list.page}
        isLoading={isLoading}
        pagination={pagination}
        onChange={onChange}
        stateReducer={(state) => state.xbox}
        checkCodes={xboxCheckCodes}
        cancelOrder={xboxCancelOrder}
        getInventoryOrder={xboxGetInventoryOrder}
        fetchOrderInfo={xboxFetchOrderInfo}
        stopOrder={xboxStopOrder}
        stopOrderReset={xboxStopOrderReset}
        isManager={isManager}
        setSelectedRow={setSelectedRow}
        selected={selectedRow}
        onSelectChange={onSelectChange}
      />
    </> 
  );
}

export default XboxOrders;
