import XboxService from '../Services/XboxService';
import { frc } from 'front-end-common';
import { createAction, createReducer } from '@reduxjs/toolkit';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const xboxAllListFetchReset = createAction('xbox/all-list-fetch_RESET')
export const xboxOrderedListFetchReset = createAction('xbox/ordered-list-fetch_RESET')

export const xboxOrderedListFetch = createAction('xbox/ordered_list', (params) => ({
  payload: XboxService.fetchOrderedList(params),
}))

export const xboxNonManagerOrderedListFetch = createAction('xbox/user_list', (params) => ({
  payload: XboxService.fetchOrderedListNonMgr(params),
}))

export const xboxActiveOrderedListFetch = createAction('xbox/active_ordered_list', (params) => ({
  payload: XboxService.fetchActiveOrderedList(params),
}))

export const xboxOrderGames = createAction('xbox/order-games', (ids,
  amount,
  maxPrice,
  orderID,
  immediate,
  buyFromStock) => ({
    payload: XboxService.orderGames(ids,
      amount,
      maxPrice,
      orderID,
      immediate,
      buyFromStock),
  }))

export const xboxOrderGamesNonMgr = createAction('xbox/order-games-non-mgr', (ids,
  amount,
  maxPrice,
  orderID) => ({
    payload: XboxService.orderGamesNonMgr(ids,
      amount,
      maxPrice,
      orderID),
  }))

export const xboxOrderGamesReset = createAction('xbox/order-games_RESET')

export const xboxSearchGamesReset = createAction('xbox/search-games_RESET')

export const xboxFetchRegions = createAction('xbox/regions', (params) => ({
  payload: XboxService.fetchRegions(params),
}));

export const xboxFetchRegionsNonMgr = createAction('xbox/regions_account', (params) => ({
  payload: XboxService.fetchRegionsNonMgr(params),
}));

// Price lists
export const xboxFetchPriceList = createAction('xbox/priceList', (params) => ({
  payload: XboxService.fetchPriceList(params),
}));

export const xboxFetchPriceListNonMgr = createAction('xbox/priceList_account', (params) => ({
  payload: XboxService.fetchPriceListNonMgr(params),
}));

export const xboxSearchPriceListCreateReset = createAction('xbox/search-price-list-create_RESET')

export const xboxStopOrder = createAction('xbox/stopOrder', (params) => ({
  payload: XboxService.stopOrder(params),
}))

export const xboxStopOrderReset = createAction('xbox/fetch-order-info_RESET')

// NEW ORDERS

export const orderFormFetch = createAction('xbox/fetchOrderForm', (params) => ({
  payload: XboxService.fetchOrderForm(params),
}))

export const orderPriceChange = createAction('xbox/editOrderPrice', (data, orderId) => ({
  payload: XboxService.changeOrderPrice(data, orderId),
}))

export const xboxFetchOrderInfo = createAction('xbox/orderInfo', (orderId) => ({
  payload: XboxService.fetchOrderInfo(orderId),
}))

export const xboxFetchOrderInfoReset = createAction('xbox/fetch-order-info_RESET')

export const xboxGetInventoryOrder = createAction('xbox/inventoryOrder', (orderId) => ({
  payload: XboxService.getInventoryOrder(orderId),
}))

export const xboxGetPriceList = createAction('xbox/xboxPricelist', (params) => ({
  payload: XboxService.getXboxPriceList(params),
}))

export const xboxCheckCodes = createAction('xbox/checkKeys', (orderId) => ({
  payload: XboxService.checkKeys(orderId),
}))

export const xboxSetFavorites = createAction('xbox/setFavorites', (params) => ({
  payload: XboxService.setFavorite(params),
}))

export const xboxCancelOrder = createAction('xbox/cancelOrder', (orderId) => ({
  payload: XboxService.cancelOrder(orderId),
}))

export const xboxGetQuantityOnStock = createAction('xbox/getQuantityOnStock', (pltId, orderId, region) => ({
  payload: XboxService.getQuantityOnStock(pltId, orderId, region),
}))

export const orderManualApprove = createAction('xbox/manualApproveOrder', (data, orderId) => ({
  payload: XboxService.manualApproveOrder(data, orderId),
}))

// updateOrderComment

export const updateComment = createAction('xbox/commentOrder', (comment, orderId) => ({
  payload: XboxService.updateOrderComment(comment, orderId),
}))

export const verifyOrder = createAction('xbox/verifyOrder', (orderId) => ({
  payload: XboxService.orderVerification(orderId),
}))

const initState = {
  all_list_fetch_reset: getDefaultState(),
  ordered_list_fetch_reset: getDefaultState(),
  all_list: getDefaultState(),
  ordered_list: getDefaultState(),
  user_list: getDefaultState(),
  active_ordered_list: getDefaultState(),
  order_games: getDefaultState(),
  order_games_non_mgr: getDefaultState(),
  order_games_reset: getDefaultState(),
  search_games_reset: getDefaultState(),
  search_price_list_create_reset: getDefaultState(),
  regions: getDefaultState(),
  regions_account: getDefaultState(),
  priceList: getDefaultState(),
  priceList_account: getDefaultState(),
  priceListCreate: getDefaultState(),
  stopOrder: getDefaultState(),
  orderInfo: getDefaultState(),
  inventoryOrder: getDefaultState(),
  xboxPricelist: getDefaultState(),
  checkKeys: getDefaultState(),
  setFavorites: getDefaultState(),
  cancelOrder: getDefaultState(),
  getQuantityOnStock: getDefaultState(),
  fetchOrderForm: getDefaultState(),
  editOrderPrice: getDefaultState(),
  manualApproveOrder: getDefaultState(),
  stop_order_reset: getDefaultState(),
  fetch_order_info_reset: getDefaultState(),
  commentOrder: getDefaultState(),
  verifyOrder: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(xboxAllListFetchReset, 'all_list_fetch_reset'),
  ...getDefaultHandler(xboxOrderedListFetchReset, 'ordered_list_fetch_reset'),
  ...getDefaultHandler(xboxOrderedListFetch, 'ordered_list'),
  ...getDefaultHandler(xboxNonManagerOrderedListFetch, 'user_list'),
  ...getDefaultHandler(xboxActiveOrderedListFetch, 'active_ordered_list'),
  ...getDefaultHandler(xboxOrderGames, 'order_games'),
  ...getDefaultHandler(xboxOrderGamesNonMgr, 'order_games_non_mgr'),
  ...getDefaultHandler(xboxOrderGamesReset, 'order_games_reset'),
  ...getDefaultHandler(xboxSearchGamesReset, 'search_games_reset'),
  ...getDefaultHandler(xboxFetchRegions, 'regions'),
  ...getDefaultHandler(xboxFetchRegionsNonMgr, 'regions_account'),
  ...getDefaultHandler(xboxFetchPriceList, 'priceList'),
  ...getDefaultHandler(xboxFetchPriceListNonMgr, 'priceList_account'),
  ...getDefaultHandler(xboxSearchPriceListCreateReset, 'search_price_list_create_reset'),
  ...getDefaultHandler(xboxStopOrder, 'stopOrder'),
  ...getDefaultHandler(xboxFetchOrderInfo, 'orderInfo'),
  ...getDefaultHandler(xboxGetInventoryOrder, 'inventoryOrder'),
  ...getDefaultHandler(xboxGetPriceList, 'xboxPricelist'),
  ...getDefaultHandler(xboxCheckCodes, 'checkKeys'),
  ...getDefaultHandler(xboxSetFavorites, 'setFavorites'),
  ...getDefaultHandler(xboxCancelOrder, 'cancelOrder'),
  ...getDefaultHandler(xboxGetQuantityOnStock, 'getQuantityOnStock'),
  ...getDefaultHandler(orderFormFetch, 'fetchOrderForm'),
  ...getDefaultHandler(orderPriceChange, 'editOrderPrice'),
  ...getDefaultHandler(orderManualApprove, 'manualApproveOrder'),
  ...getDefaultHandler(xboxStopOrderReset, 'stop_order_reset'),
  ...getDefaultHandler(xboxFetchOrderInfoReset, 'fetch_order_info_reset'),
  ...getDefaultHandler(updateComment, 'commentOrder'),
  ...getDefaultHandler(verifyOrder, 'verifyOrder'),
})
