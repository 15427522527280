/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Input, message, Row, Typography } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import ListWrapper from '../../HOC/ListWrapper';
import {
  xboxAllListFetchReset,
  xboxFetchPriceList,
  xboxFetchRegions,
  xboxOrderGames,
  xboxOrderGamesReset,
  xboxSearchGamesReset,
  xboxSetFavorites,
  xboxGetQuantityOnStock,
  xboxGetPriceList,
  xboxFetchPriceListNonMgr,
  xboxFetchRegionsNonMgr,
  xboxOrderGamesNonMgr
} from '../../Reducers/xbox';
import TableGameList from '../Price/TableGameList';
import UserSettingsService from '../../Services/UserSettingsService';
import './styles.scss';
import XboxFilters from '../Price/PriceFilters';
import useTable from '../../Utils/useTable';
import { DislikeOutlined, DownloadOutlined } from '@ant-design/icons';
import { Navigate, useOutletContext } from 'react-router-dom';

const { Search } = Input

/** This is Page */
function XboxPrice() {

  const isManager = useOutletContext();
  const [priceList, setPriceList] = useState("");

  const {
    list,
    pagination,
    onChange: parentOnChange,
    onFilter,
    isLoading,
    fetch: fetchList,
    filters,
    error,
    query,
    onSearch,
    sort
  } = useTable(state => state.xbox[priceList], priceList === 'priceList' ? xboxFetchPriceList : priceList === 'priceList_account' ? xboxFetchPriceListNonMgr : null);

  useEffect(() => {
    if (isManager !== null) {
    if (isManager) {
      setPriceList("priceList");
    } else {
      setPriceList("priceList_account");
    }
  }
  }, [isManager])

  const dispatch = useDispatch();

  const onChange = (a, b, c) =>
    UserSettingsService.onChangeSettings('XBOXPrice', parentOnChange, a, b, c);

  // const onFilter = () => 
  //   UserSettingsService.setFilters('XBOXPrice', parentOnFilter, filters);

  useEffect(
    () => {
      const { filters, query } = UserSettingsService.getSorterAndFiltersAndQueryAndPageSize('XBOXPrice')
      onFilter(filters, query);
      UserSettingsService.setFilters('XBOXPrice', onFilter, filters);
      // onFilter(
      //   ...UserSettingsService.getSorterAndFiltersAndQueryAndPageSize(
      //     'XBOXPrice'
      //   )
      // );

      // return () => {
      //   dispatch(xboxAllListFetchReset());
      //   dispatch(xboxSearchGamesReset());
      // };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, filters]
  );

  const { isLoading: setFavoritesLoading } = useSelector(
    (state) => state.xbox.setFavorites,
    shallowEqual
  );

  const setFavorites = useCallback(
    (data) => {
      dispatch(xboxSetFavorites(data))
      .then(() => {
        message.success('Favorites has been updated!');
        fetchList();
      })
      .catch((error) => {
        message.error(error);
      })
    },
    [dispatch, fetchList]
  );

  const payload = useSelector(
    (state) => state.core.customer.id,
    shallowEqual
  );


  const handleDownload = () => {

    const params = { filters, query }
    dispatch(xboxGetPriceList(params))
    .catch((error) => {
      console.log('ERROR:', error)
      message.error(`Failed to download price list: ${error.message}`)
    })
  }

  const regions = isManager ? xboxFetchRegions : xboxFetchRegionsNonMgr

  console.log(`LIST: ${list}`)

  return (
    <>
      <Typography.Title>XBOX Price</Typography.Title>
      <Button
              // size="small"
              // loading={isLoading}
              onClick={handleDownload}
              icon={<DownloadOutlined />}
              type="primary"
              style={{marginBottom: '24px'}}
              // block
            >
              Download Pricelist
      </Button>
      {isManager && <a href="/blacklist" className="blacklist_link">To Blacklist {">"}</a>}
      <XboxFilters
        className="mb"
        query={query}
        filters={filters}
        setFiltersAndQuery={onFilter}
        stateReducer={(state) => state.xbox}
        fetchRegions={regions}
        customer={payload}
        afterUpdate={fetchList}
      />
      <Row gutter={16}>
        <Col span={24}>
          <Search
            defaultValue={query} 
            onSearch={onSearch}
            placeholder="Name"
            className="mb"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <TableGameList
            isBlizzard={false}
            blacklisted={true}
            list={list.list}
            isLoading={isLoading || setFavoritesLoading}
            pagination={pagination}
            setFavorites={setFavorites}
            getQuantityOnStock={xboxGetQuantityOnStock}
            selections
            onChange={(a, b, c) => onChange(a, b, c)}
            sorter={sort}
            // sorter={sorter}
            stateReducer={(state) => state.xbox}
            orderGames={xboxOrderGames}
            orderGamesNonMgr={xboxOrderGamesNonMgr}
            orderGamesReset={xboxOrderGamesReset}
            afterUpdate={fetchList}
            isUserAllowed={isManager}
          />
        </Col>
      </Row>
    </>
  );
}

export default XboxPrice;
