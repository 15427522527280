import {
  wholesaleV2 as ApiService,
  wholesaleV2withMgr as ApiwholesaleV2withMgrService,
} from './ApiService';
import { downloadFile } from '../Utils/apiHelper';
import { getCurrentCustomerFromLS } from './CoreService';

function prepareBalance(items) {
  let balance = items.shift().amount;

  return {
    startBalance: balance,
    list: items
      .map((item) => {
        balance += item.amount;
        return {
          ...item,
          balance,
        };
      })
      .reverse(),
  };
}

const from = 1;
const to = 1589989388332;

export default {
  fetchList([from, to]) {
    //https://wholesale.hoteos.net/api/v2
    const currentCustomer = getCurrentCustomerFromLS();
    return ApiService.accessGet(`/mgr/transactions?customer_id=${currentCustomer.id}&from=${from}&to=${to}`);
  },
  fetchListNonMgr([from, to]) {
    //https://wholesale.hoteos.net/api/v2
    return ApiService.accessGet(`/account/transactions?from=${from}&to=${to}`);
  },
  generatePDF() {
    return ApiService.accessPost(`/invoice?from=${from}&to=${to}&fmt=pdf`).then(
      downloadFile
    );
  },
  generateCSV() {
    return ApiService.accessPost(`/invoice?from=${from}&to=${to}&fmt=csv`).then(
      downloadFile
    );
  },

  changeBalance(params) {
    ApiwholesaleV2withMgrService.post(`/customer/transaction`, params);

    // return new Promise((resolve) => {
    //   setTimeout(resolve, 1000, mockList);
    // });
  },
  // zeroizeBalance(id) {
  //   ApiwholesaleV2withMgrService.accessPost(`${crgm}/customers-zeroize-balance/${id}`);

  //   return new Promise((resolve) => {
  //     setTimeout(resolve, 1000, mockList);
  //   });
  // },
};
