import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import GetInventoryOrderButton from '../../OrderItem/GetInventoryOrderButton';
import StopOrderButton from '../../OrderItem/StopOrderButton';
import CheckKeys from '../../CheckKeys';
import CancelOrderButton from '../../OrderItem/CancelOrderButton';
import PrintManualApproveOrder from '../../../../Components/PrintManualApproveOrder';
import VerifyButton from '../../VerifyButton';

function ColumnButtons({
  orderId,
  status,
  afterSuccess,
  filled,
  stateReducer,
  checkCodes,
  cancelOrder,
  getInventoryOrder,
  fetchOrderInfo,
  stopOrder,
  stopOrderReset,
  manualApproval,
  isManager
}) {

  return (
    <div style={{ display: 'flex' }}>
      {isManager &&
      <>
      {((status === 1 && manualApproval) || status === 8) && (
      <PrintManualApproveOrder
      className="button-margin-small"
                orderId={orderId}
              />
      )}
      {([1, 2, 8].includes(status)) && (
        <StopOrderButton
          afterSuccess={afterSuccess}
          orderId={orderId}
          stateReducer={stateReducer}
          fetchOrderInfo={fetchOrderInfo}
          stopOrder={stopOrder}
          stopOrderReset={stopOrderReset}
          className="button-margin-small"
        />
      )}
      {([3, 9].includes(status)) && (
            <CancelOrderButton
              className="button-margin-small"
              orderId={orderId}
              afterSuccess={afterSuccess}
              filled={filled}
              stateReducer={stateReducer}
              cancelOrder={cancelOrder}
            />
      )}
      {([3, 4].includes(status)) && (
        <CheckKeys
          afterSuccess={afterSuccess}
          className="button-margin-small"
          orderId={orderId}
          checkCodes={checkCodes}
        />
      )}
      {([9].includes(status)) && (
        <VerifyButton 
        className="button-margin-small"
        afterSuccess={afterSuccess}
        orderId={orderId}
        />
      )}
      </>
    }
      {status === 3 && (
          <GetInventoryOrderButton
            className="button-margin-small"
            orderId={orderId}
            stateReducer={stateReducer}
            getInventoryOrder={getInventoryOrder}
          />
        )}
    </div>
  );
}

export default React.memo(ColumnButtons);
