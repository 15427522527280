import BlizzardService from '../Services/BlizzardService';
import { frc } from 'front-end-common';
import { createAction, createReducer } from '@reduxjs/toolkit';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const blizzardAllListFetchReset = createAction('blizzard/all-list-fetch_RESET')
export const blizzardOrderedListFetchReset = createAction('blizzard/ordered-list-fetch_RESET')


export const blizzardOrderedListFetch = createAction('blizzard/ordered_list', (params) => ({
  payload: BlizzardService.fetchOrderedList(params),
}))

export const blizzardOrderGames = createAction('blizzard/regions', (
  ids,
  amount,
  maxPrice,
  orderID,
  immediate,
  buyFromStock) => ({
    payload: BlizzardService.orderGames(
      ids,
      amount,
      maxPrice,
      orderID,
      immediate,
      buyFromStock),
  }))

export const blizzardOrderGamesNonMgr = createAction('blizzard/order-games-non-mgr', (ids,
  amount,
  maxPrice,
  orderID) => ({
    payload: BlizzardService.orderGamesNonMgr(ids,
      amount,
      maxPrice,
      orderID),
  }))

export const blizzardOrderGamesReset = createAction('blizzard/order-games_RESET')

export const blizzardSearchGamesReset = createAction('blizzard/search-games_RESET')

export const blizzardFetchRegions = createAction('blizzard/regions', () => ({
  payload: BlizzardService.fetchRegions(),
}))

export const blizzardFetchPriceList = createAction('blizzard/price_list', (params) => ({
  payload: BlizzardService.fetchPriceList(params),
}))

export const blizzardFetchPriceListNonMgr = createAction('blizzard/price_list_account', (params) => ({
  payload: BlizzardService.fetchPriceListNonMgr(params),
}))

export const blizzardSearchPriceListCreateReset = createAction('blizzard/search-price-list-create_RESET')

export const blizzardStopOrder = createAction('blizzard/stopOrder', (orderId) => ({
  payload: BlizzardService.stopOrder(orderId),
}))

export const blizzardStopOrderReset = createAction('blizzard/stop-order_RESET')

export const blizzardFetchOrderInfo = createAction('blizzard/order_info', (orderId) => ({
  payload: BlizzardService.fetchOrderInfo(orderId),
}))

export const blizzardFetchOrderInfoReset = createAction('blizzard/fetch-order-info_RESET')

export const blizzardGetInventoryOrder = createAction('blizzard/getInventoryOrder', (orderId) => ({
  payload: BlizzardService.getInventoryOrder(orderId),
}))

export const blizzardCheckCodes = createAction('blizzard/checkCodes', (orderId) => ({
  payload: BlizzardService.checkKeys(orderId),
}))

export const blizzardCancelOrder = createAction('blizzard/cancelOrder', (orderId) => ({
  payload: BlizzardService.cancelOrder(orderId),
}))

export const blizzardGetQuantityOnStock = createAction('blizzard/getQuantityOnStock', (pltId, orderId, region) => ({
  payload: BlizzardService.getQuantityOnStock(pltId, orderId, region),
}))

const initState = {
  all_list_fetch_reset: getDefaultState(),
  ordered_list_fetch_reset: getDefaultState(),
  ordered_list: getDefaultState(),
  order_games: getDefaultState(),
  order_games_non_mgr: getDefaultState(),
  order_games_reset: getDefaultState(),
  search_games_reset: getDefaultState(),
  search_games: getDefaultState(),
  regions: getDefaultState(),
  priceList: getDefaultState(),
  price_list_account: getDefaultState(),
  search_price_list_create_reset: getDefaultState(),
  stopOrder: getDefaultState(),
  orderInfo: getDefaultState(),
  inventoryOrder: getDefaultState(),
  xboxPricelist: getDefaultState(),
  checkKeys: getDefaultState(),
  setFavorites: getDefaultState(),
  cancelOrder: getDefaultState(),
  getQuantityOnStock: getDefaultState(),
  fetchOrderForm: getDefaultState(),
  editOrderPrice: getDefaultState(),
  manualApproveOrder: getDefaultState(),
  stop_order_reset: getDefaultState(),
  fetch_order_info_reset: getDefaultState()
};

export default createReducer(initState, {
  ...getDefaultHandler(blizzardAllListFetchReset, 'all_list_fetch_reset'),
  ...getDefaultHandler(blizzardOrderedListFetchReset, 'ordered_list_fetch_reset'),
  ...getDefaultHandler(blizzardOrderedListFetch, 'ordered_list'),
  ...getDefaultHandler(blizzardOrderGames, 'order_games'),
  ...getDefaultHandler(blizzardOrderGamesNonMgr, 'order_games_non_mgr'),
  ...getDefaultHandler(blizzardOrderGamesReset, 'order_games_reset'),
  ...getDefaultHandler(blizzardSearchGamesReset, 'search_games_reset'),
  ...getDefaultHandler(blizzardFetchRegions, 'regions'),
  ...getDefaultHandler(blizzardFetchPriceList, 'priceList'),
  ...getDefaultHandler(blizzardFetchPriceListNonMgr, 'price_list_account'),
  ...getDefaultHandler(blizzardSearchPriceListCreateReset, 'search_price_list_create_reset'),
  ...getDefaultHandler(blizzardStopOrder, 'stopOrder'),
  ...getDefaultHandler(blizzardFetchOrderInfo, 'orderInfo'),
  ...getDefaultHandler(blizzardGetInventoryOrder, 'inventoryOrder'),
  ...getDefaultHandler(blizzardCheckCodes, 'checkKeys'),
  ...getDefaultHandler(blizzardCancelOrder, 'cancelOrder'),
  ...getDefaultHandler(blizzardGetQuantityOnStock, 'getQuantityOnStock'),
  ...getDefaultHandler(blizzardStopOrderReset, 'stop_order_reset'),
  ...getDefaultHandler(blizzardFetchOrderInfoReset, 'fetch_order_info_reset')
})
