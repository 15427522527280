import { Button } from 'antd';
import React from 'react';
import PrintAmount from '../../../Components/Print/PrintAmount';
import PrintDate from '../../../Components/PrintDate';
import PrintOrderForm from '../../../Components/PrintOrderForm';
import PrintStatusOrder from '../../../Components/Xbox/PrintStatusOrder';
import ColumnButtons from './Columns/ColumnButtons';
import ColumnOrderedFilled from './Columns/ColumnOrderedFilled';
import ColumnProducts from './Columns/ColumnProducts';
import ColumnComment from './Columns/ColumnComment';

// eslint-disable-next-line import/prefer-default-export
export function getColumns(
  b,
  afterSuccess,
  stateReducer,
  checkCodes,
  cancelOrder,
  getInventoryOrder,
  fetchOrderInfo,
  stopOrder,
  stopOrderReset,
  isManager
) {
  return [
    {
      title: 'OrderId',
      dataIndex: 'orderId',
      width: 112,
      render: (_, { status, orderId }) => <PrintOrderForm status={status} orderId={orderId} afterSuccess={afterSuccess} />
    },
    {
      title: 'Status',
      width: 120,
      align: 'center',
      dataIndex: 'status',
      render: (status) => status && <PrintStatusOrder status={status} />,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (amount) => <PrintAmount amount={amount} currency="EUR" />,
    },
    {
      title: 'Date / Time',
      width: 160,
      dataIndex: 'cre',
      // eslint-disable-next-line react/display-name
      render: (date) => <PrintDate date={date} withTime />,
    },
    {
      title: 'Products',
      width: 300,
      className: b('products'),
      dataIndex: 'products',
      // eslint-disable-next-line react/display-name
      render: (products) => <ColumnProducts products={products} />,
    },
  (isManager ?
        {
      title: 'Jobs',
      dataIndex: 'jobs',
      width: 152,
      className: b('jobs'),
      render: (jobs = []) =>
        (jobs || []).map(({ jobId, result }) => (
          <a
            key={jobId}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://paa.hoteos.net/jobs?jobId=${jobId}&dateFrom=&dateTo=&status=&code=&gameId=&jobName=Purchase`}
          >
            {jobId} ({result})
          </a>
        )),
    } : {className: b('jobs_hidden') } ),
    {
      title: 'Ordered / Filled',
      width: 140,
      dataIndex: 'filled',
      // eslint-disable-next-line react/display-name
      render: (filled, { qty }) => (
        <ColumnOrderedFilled filled={filled} ordered={qty} />
      ),
    },
    (isManager ?
    {
      title: 'Customer',
      width: 100,
      dataIndex: 'customer',
      // eslint-disable-next-line react/display-name
      // render: (filled, { qty }) => <span>{customer}</span>
    } : {className: b('jobs_hidden') } ),
    {
      title: 'Action',
      width: 250,
      dataIndex: 'buttons',
      // eslint-disable-next-line react/display-name
      render: (_, { status, orderId, filled }) => (
        <ColumnButtons
          afterSuccess={afterSuccess}
          status={status}
          orderId={orderId}
          filled={filled}
          stateReducer={stateReducer}
          checkCodes={checkCodes}
          cancelOrder={cancelOrder}
          getInventoryOrder={getInventoryOrder}
          fetchOrderInfo={fetchOrderInfo}
          stopOrder={stopOrder}
          stopOrderReset={stopOrderReset}
          isManager={isManager}
        />
      ),
    },
    {
      title: 'Comments',
      width: 250,
      dataIndex: 'comment',
      // eslint-disable-next-line react/display-name
      render: (comment, { orderId }) => (
        <ColumnComment
          comment={comment}
          orderId={orderId}
        />
      ),
    },
  ];
}
