import uuid4 from 'uuid/v4';
import uuid5 from 'uuid/v5';
import { downloadFile } from '../Utils/apiHelper';
import { snakeToCamel } from '../Utils/textTools';
import {
  wholesaleV2 as ApiWholesaleV2Service,
  merchantV2 as ApiMerchantV2Service,
  wholesaleV2withMgr as ApiwholesaleV2withMgrService,
  wholesaleV2WithoutPrefix as ApiwholesaleV2withoutPrefix,
  wholesaleV2withAccount as wholesaleV2withAccountService
} from './ApiService';
import { getCurrentCustomerFromLS } from './CoreService';

// function prepareData(items) {
//   return items.map((item) => ({
//     ...item,
//     id: `${item.product_id}:${item.region}`,
//   }));
// }

function newPrepareData(items) {
  return items.map((item) => ({
    ...item,
    id: `${item.productId}:${item.region}`,
  }));
}

function newIdToObject(id) {
  const [productId, region] = id.split(':');

  return {
    productId,
    region,
  };
}

function preparedOrderedItem(item) {
  if (item.products) {
    const currentProducts = [...item.products];
    const newProducts = [];

    currentProducts.forEach((product) => {
      const currentProductInfo = { ...product };
      delete currentProductInfo.items;

      currentProductInfo.countItems = product.items.length;
      currentProductInfo.parentId = `${currentProductInfo.product_id}:${currentProductInfo.region}`;

      product.items.forEach((currentItem, i) => {
        const newData = { ...currentProductInfo, ...currentItem };

        if (i !== 0) {
          delete newData.productId;
          delete newData.region;
          delete newData.game;
        }
        newProducts.push(newData);
      });
    });

    return { ...item, products: newProducts };
  }

  return item;
}

export default {
  orderGames(pltId, ids, count, maxPrice, orderID, immediate, buyFromStock) {
    const items = ids.map((id) => ({
      ...newIdToObject(id),
      qty: count,
      maxPrice,
      pltId: pltId,
    }));
    const reqId = uuid5(JSON.stringify(items), uuid4());
    const q = `&immediate=${immediate}`;
    const currentCustomer = getCurrentCustomerFromLS();

    //https://merchant-router.hoteos.net/api/v2
    return ApiMerchantV2Service.accessPost(
      `/order?customer_id=${currentCustomer.id}${q}`,
      {
        reqId,
        ext: orderID,
        products: items,
        buyFromStock,
      }
    );
  },

  orderGamesNonMgr(pltId, ids, count, maxPrice, orderID) {
    const items = ids.map((id) => ({
      ...newIdToObject(id),
      qty: count,
      maxPrice,
      pltId: pltId,
    }));
    const reqId = uuid5(JSON.stringify(items), uuid4());

    //https://merchant-router.hoteos.net/api/v2
    return ApiMerchantV2Service.accessPost(
      `/order`,
      {
        reqId,
        ext: orderID,
        products: items,
      }
    );
  },

  fetchOrderedList({ filters, orderPath, ...params }) {
    const currentCustomer = getCurrentCustomerFromLS();

    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (const key in filters) {
        updatedParams[snakeToCamel(key)] = Array.isArray(filters[key])
          ? filters[key].join(',')
          : filters[key];
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    //https://wholesale.hoteos.net/api/v2
    return ApiwholesaleV2withMgrService.get(
      `/orders?customer_id=${currentCustomer.id || '0'}&`,
      updatedParams
    );
  },

  fetchOrderedListNonMgr({ filters, orderPath, ...params }) {

    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (const key in filters) {
        updatedParams[snakeToCamel(key)] = Array.isArray(filters[key])
          ? filters[key].join(',')
          : filters[key];
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    //https://wholesale.hoteos.net/api/v2
    return wholesaleV2withAccountService.get(
      `/orders`,
      updatedParams
    );
  },

  fetchActiveOrderedList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        if (key === 'from') {
          updatedParams.from = filters.from;
        } else if (key === 'to') {
          updatedParams.to = filters.to;
        } else if (key === 'status') {
          updatedParams.status = filters.status.join(',');
        } else {
          updatedParams[key] = filters[key];
        }
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    //https://wholesale.hoteos.net/api/v2
    return ApiwholesaleV2withMgrService.get(
      `/orders/active`,
      updatedParams
    );
  },

  //https://wholesale.hoteos.net/api/v2
  fetchRegions() {
    const currentCustomer = getCurrentCustomerFromLS();

    return ApiWholesaleV2Service.accessGet(
      `/xbox/regions?customer_id=${currentCustomer.id || '0'}&`
    );
  },

  fetchRegionsNonMgr() {
    return ApiWholesaleV2Service.accessGet(
      `/xbox/regions`
    );
  },

  // fetchPriceList({ filters = {}, ...params }) {
  //   const currentCustomer = getCurrentCustomerFromLS();
  //   const newFilter = {};

  //   for (const key in filters) {
  //     newFilter[snakeToCamel(key)] = Array.isArray(filters[key])
  //       ? filters[key].join(',')
  //       : filters[key];
  //   }

  //   //https://wholesale.hoteos.net/api/v2
  //   return ApiWholesaleV2Service.accessGet(
  //     `/xbox/prices?customer_id=${currentCustomer.id || '0'}&`,
  //     {
  //       ...params,
  //       ...newFilter,
  //     }
  //   ).then((data) => ({
  //     list: newPrepareData(data.page),
  //     total: data.total,
  //   }));
  // },
  fetchPriceList({ filters = {}, ...params }) {
    const currentCustomer = getCurrentCustomerFromLS();
    const newFilter = {};

    if (Object.keys(filters).length > 0) {
      for (const key in filters) {
        newFilter[snakeToCamel(key)] = Array.isArray(filters[key])
          ? filters[key].join(',')
          : filters[key];
      }

      const baseUrl = `/xbox/prices?customer_id=${currentCustomer.id || '0'}`;
      const queryString = new URLSearchParams({
        ...params,
        ...newFilter,
      }).toString();
      const url = `${baseUrl}&${queryString}`;

      // Make the API request using the corrected URL
      return ApiWholesaleV2Service.accessGet(url).then((data) => ({
        list: newPrepareData(data.page),
        total: data.total,
      }));
    } else {
      const baseUrl = `/xbox/prices?customer_id=${currentCustomer.id || '0'}`;
      const queryString = new URLSearchParams({
        ...params,
        ...newFilter,
      }).toString();
      const url = `${baseUrl}&${queryString}`;

      // Make the API request using the corrected URL
      return ApiWholesaleV2Service.accessGet(url).then((data) => ({
        list: newPrepareData(data.page),
        total: data.total,
      }));
    }
  },

  fetchPriceListNonMgr({ filters = {}, ...params }) {
    const newFilter = {};

    if (Object.keys(filters).length > 0) {
      for (const key in filters) {
        newFilter[snakeToCamel(key)] = Array.isArray(filters[key])
          ? filters[key].join(',')
          : filters[key];
      }

      const baseUrl = `/xbox/prices`;
      const queryString = new URLSearchParams({
        ...params,
        ...newFilter,
      }).toString();
      const url = `${baseUrl}?${queryString}`;

      // Make the API request using the corrected URL
      return ApiWholesaleV2Service.accessGet(url).then((data) => ({
        list: newPrepareData(data.page),
        total: data.total,
      }));
    } else {
      const baseUrl = `/xbox/prices`;
      const queryString = new URLSearchParams({
        ...params,
        ...newFilter,
      }).toString();
      const url = `${baseUrl}?${queryString}`;

      // Make the API request using the corrected URL
      return ApiWholesaleV2Service.accessGet(url).then((data) => ({
        list: newPrepareData(data.page),
        total: data.total,
      }));
    }
  },

  //https://merchant-router.hoteos.net/api/v2
  stopOrder(orderId) {
    return ApiMerchantV2Service.accessPatch(`/order/${orderId}/stop`);
  },

  //https://merchant-router.hoteos.net/api/v2
  fetchOrderInfo(orderId) {
    return ApiMerchantV2Service.accessGet(`/order/${orderId}`).then((resp) =>
      preparedOrderedItem(resp)
    );
  },

  fetchOrderForm(orderId) {
    return ApiMerchantV2Service.accessGet(`/order/${orderId}`).then((resp) =>
      preparedOrderedItem(resp)
    );
  },

  changeOrderPrice(data, orderId) {
    return ApiwholesaleV2withMgrService.accessPatch(
      `/order/${orderId}/items`,
      data
    );
  },

  manualApproveOrder(data, orderId) {
    return ApiwholesaleV2withMgrService.accessPatch(
      `/order/${orderId}/execute`,
      data
    );
  },

  //https://wholesale.hoteos.net/api/v2
  getInventoryOrder(orderId) {
    const currentCustomer = getCurrentCustomerFromLS();

    return ApiwholesaleV2withMgrService.accessGet(
      `/order/${orderId}/inventory?customer_id=${currentCustomer.id}`
    ).then(downloadFile);
  },

  getXboxPriceList({ filters = {}, ...params }) {
    const currentCustomer = getCurrentCustomerFromLS();
    const newFilter = {};

    for (const key in filters) {
      newFilter[snakeToCamel(key)] = Array.isArray(filters[key])
        ? filters[key].join(',')
        : filters[key];
    }

    //https://wholesale.hoteos.net/api/v2
    return ApiWholesaleV2Service.accessGet(
      `/xbox/prices/file?customer_id=${currentCustomer.id || '0'}&`,
      {
        ...params,
        ...newFilter,
      }
    ).then(downloadFile);
  },

  //https://merchant-router.hoteos.net/api/v2
  checkKeys(orderId) {
    const currentCustomer = getCurrentCustomerFromLS();

    return ApiMerchantV2Service.accessPatch(
      `/order/${orderId}/takekeys?customer_id=${currentCustomer.id}`
    );
  },

  //https://merchant-router.hoteos.net/api/v2
  cancelOrder(orderId) {
    return ApiMerchantV2Service.accessPatch(`/order/${orderId}/cancel`);
  },

  setFavorite(data) {
    const currentCustomer = getCurrentCustomerFromLS();
    const { action, productId, region } = data;

    //https://wholesale.hoteos.net/api/v2
    return ApiWholesaleV2Service.accessPut(
      `/xbox/prices/tags?customer_id=${currentCustomer.id}`,
      {
        action,
        tags: ['favorites'],
        ids: [{ productId, region }],
      }
    );
  },

  //https://wholesale.hoteos.net/api/v2
  //Platfrom: Xbox = 2
  getQuantityOnStock(pltId, productId, region) {
    return ApiWholesaleV2Service.accessGet(
      `/stock?plt_id=${pltId}&product_id=${productId}&region=${region}`
    );
  },

  updateOrderComment(comment, orderId) {
    return ApiwholesaleV2withMgrService.accessPatch(
      `/order/${orderId}/comment`, comment
    );
  },

  orderVerification(orderId) {
    return ApiwholesaleV2withMgrService.accessPatch(
      `/order/${orderId}/verify`
    );
  }
};
