import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, message, Tooltip } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { coreFetchCustomers } from '../../../Reducers/core';
import useBoolean from '../../../Utils/useBoolean';
import OrderKeysModal from '../OrderKeysModal';
import { ShoppingCartOutlined } from '@ant-design/icons';

const initalValue = {
  countKey: 1,
};

function OrderButton({
  pltId,
  productId,
  region,
  defaultMaxPrice,
  game,
  prices,
  stateReducer,
  orderGames,
  orderGamesNonMgr,
  orderGamesReset,
  getQuantityOnStock,
  isUserAllowed
}) {
  const dispatch = useDispatch();

  const { value: visible, setTrue: open, setFalse: close } = useBoolean(false);
  const [number, setNumber] = useState(initalValue.countKey);
  const [maxPrice, setMaxPrice] = useState(defaultMaxPrice);
  const [orderID, setOrderID] = useState('');
  const stockAmount = useMemo(
    () => prices.reduce((acc, cur) => (cur.amount || 0) + acc, 0),
    [prices]
  );
  const { buyFromStockSetting, skipOrderPriceValidation } = useSelector(
    (state) => ({
      buyFromStockSetting: !isUserAllowed ? false :
      state.core.customer
        ? state.core.customer.params.buyFromStock
        : false,
      skipOrderPriceValidation: !isUserAllowed ? false :
      state.core.customer
        ? state.core.customer.params.skipOrderPriceValidation
        : false,
    }),
    shallowEqual
  );
  
  const {
    value: buyFromStock,
    setValue: setBuyFromStock,
    toggleValue: toggleBuyFromStock,
  } = useBoolean(buyFromStockSetting);
  
  const changeBuyFromStock = useCallback(() => {
    toggleBuyFromStock();
  }, [toggleBuyFromStock]);

  useEffect(() => {
    setBuyFromStock(stockAmount === 0 ? false : buyFromStockSetting);
  }, [buyFromStockSetting, setBuyFromStock, stockAmount]);

  const changeOrderID = useCallback(
    (e) => {
      setOrderID(e.target.value);
    },
    [setOrderID]
  );

  const { orderGamesLoading } = useSelector(
    (state) => ({
      orderGamesLoading: stateReducer(state).order_games.isLoading,
    }),
    shallowEqual
  );

  const maxOnDemandKeys = useSelector(
    (state) =>
      isUserAllowed ? 
        state.core.customer
        ? state.core.customer.params.maxOnDemandKeys
        : 0 
        : state.core.account ? 
          state.core.account.params ? 
          state.core.account.params.maxOnDemandKeys : 0
          : 0 ,
    shallowEqual
  );

  console.log(maxOnDemandKeys)

  const {
    value: immediate,
    toggleValue: toggleImmediate,
    setValue: setImmediate,
  } = useBoolean(maxOnDemandKeys >= number);

  const {
    value: isTouchedImmediate,
    setTrue: touchImmediate,
    setFalse: disableTouch,
  } = useBoolean(false);

  const changeImmediate = useCallback(() => {
    toggleImmediate();
    touchImmediate();
  }, [touchImmediate, toggleImmediate]);

  useEffect(() => {
    if (!isTouchedImmediate) {
      setImmediate(maxOnDemandKeys >= number);
    }
    if (!visible) {
      setNumber(initalValue.countKey);
      setOrderID('');
      disableTouch();
    }
  }, [isTouchedImmediate, visible, maxOnDemandKeys, number, setImmediate]);

  const [quantityOnStock, setQuantityOnStock] = useState('-');

  const handleOnClick = () => {
    dispatch(getQuantityOnStock(pltId, productId, region), [])
      .then((res) => setQuantityOnStock(res.value.qty))

    open() 
  }

  const onOk = useCallback(() => {
    dispatch(
      isUserAllowed ? orderGames(
        pltId,
        [`${productId}:${region}`],
        number,
        typeof maxPrice === 'number' ? maxPrice.toFixed(2) : maxPrice,
        orderID,
        immediate,
        buyFromStock
      ) : orderGamesNonMgr(
        pltId,
        [`${productId}:${region}`],
        number,
        typeof maxPrice === 'number' ? maxPrice.toFixed(2) : maxPrice,
        orderID
      )
    )
      .then((resp) => {
        close();
        setNumber(initalValue.countKey);
        const orderNumber =
          resp && resp.value && resp.value.orderId
            ? ` #${resp.value.orderId}`
            : '';
        message.success(
          `The successful creation of the order${orderNumber}! Open the Ordered page for more information.`
        );
        dispatch(coreFetchCustomers());
      })
      .catch((error) => {
        message.error(error.errors[0].message);
      })
      .catch((error) => {
        message.error(error.message || error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, region, number, close, maxPrice, orderID, immediate, buyFromStock]);

  const disableBuyFromStock = useMemo(() => !!buyFromStock, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(orderGamesReset()), []);

  return (
    <>
      <Tooltip title="Order Keys">
        <Button
          type="primary"
          size="small"
          icon={<ShoppingCartOutlined />}
          onClick={handleOnClick}
          disabled={!productId}
          loading={orderGamesLoading}
        />
      </Tooltip>
      <OrderKeysModal
        loading={orderGamesLoading}
        visible={visible}
        immediate={immediate}
        changeImmediate={changeImmediate}
        close={close}
        setNumber={setNumber}
        number={number}
        onOk={onOk}
        maxPrice={maxPrice}
        setMaxPrice={setMaxPrice}
        quantityOnStock={quantityOnStock}
        buyFromStock={buyFromStock}
        changeBuyFromStock={changeBuyFromStock}
        buyFromStockSetting={buyFromStockSetting}
        disableBuyFromStock={disableBuyFromStock}
        skipOrderPriceValidation={skipOrderPriceValidation}
        stockAmount={stockAmount}
        game={game}
        region={region}
        changeOrderID={changeOrderID}
        orderID={orderID}
        pltId={pltId}
        isUserAllowed={isUserAllowed}
      />
    </>
  );
}

OrderButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pltId: PropTypes.number,
  productId: PropTypes.string.isRequired,
  defaultMaxPrice: PropTypes.number,
  game: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  prices: PropTypes.any.isRequired,
  stateReducer: PropTypes.func.isRequired,
  orderGames: PropTypes.func.isRequired,
  orderGamesReset: PropTypes.func.isRequired,
};

OrderButton.defaultProps = {
  defaultMaxPrice: 0,
};

export default OrderButton;
