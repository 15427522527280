import React from 'react';
import { Checkbox, Col, Input, InputNumber, Modal, Row, Slider } from 'antd';
import PropTypes from 'prop-types';

/** Modal window for selecting the number of links */
function OrderKeysModal({
  visible,
  close,
  onOk,
  number,
  setNumber,
  loading,
  maxPrice,
  game,
  region,
  orderID,
  pltId,
  changeOrderID,
  immediate,
  buyFromStock,
  changeBuyFromStock,
  buyFromStockSetting,
  disableBuyFromStock,
  stockAmount,
  skipOrderPriceValidation,
  setMaxPrice,
  changeImmediate,
  quantityOnStock,
  isUserAllowed
}) {
  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={close}
      onOk={onOk}
      closable={false}
      maskClosable
      title={`Order Keys: ${game || ''} (${region})`}
      okButtonProps={{
        disabled:
          number < 1 ||
          number > 500 ||
          // number > (buyFromStock ? stockAmount : 500) ||
          !maxPrice,
      }}
      cancelButtonProps={{ disabled: loading }}
      confirmLoading={loading}
    >
      
      <Row className="mb-small">
        <Col span={4} className="flex-left" style={{ minHeight: '32px' }}>
          <b>Quantity:</b>
        </Col>
        <Col span={15}>
          <Slider
            min={1}
            // max={buyFromStock ? stockAmount : 500}
            max={500}
            onChange={setNumber}
            value={typeof number === 'number' ? number : 0}
          />
        </Col>
        <Col span={5}>
          <InputNumber
            min={1}
            // max={buyFromStock ? stockAmount : 500}
            max={500}
            className="ml fr"
            value={number}
            onChange={(v) => {
              let n = Math.round(v);
              if (n < 1) n = 1;
              // const max = buyFromStock ? stockAmount : 500;
              const max = 500;
              if (n > max) n = max;
              setNumber(n);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-small">
        <Col span={19} className="flex-left" style={{ minHeight: '32px' }}>
          <b>Sell price, €:</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
          <InputNumber
            readOnly={!skipOrderPriceValidation}
            className="fr"
            min={0.01}
            value={maxPrice}
            precision={2}
            onChange={setMaxPrice}
          />
        </Col>
      </Row>
      <Row className="flex-left mb-small">
        <Col span={6}>
          <b>Order ID:</b>
        </Col>
        <Col span={18}>
          <Input
            value={orderID}
            onChange={changeOrderID}
            type="text"
            className="w100p"
          />
        </Col>
      </Row>
      {isUserAllowed && <Row style={{ marginTop: 15 }} >
        <Col span={19} className="flex-left" >
          <b>Immediate</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
          <Checkbox
            onChange={changeImmediate}
            className="fr"
            checked={immediate}
          />
        </Col>
      </Row> }
      <Row style={{ marginTop: 15 }} >
        <Col span={19} className="flex-left" >
          <b>Quantity on stock:</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }} >
          <span style={{ float:"right" }}>{quantityOnStock}</span>
        </Col>
      </Row>
      {isUserAllowed && <Row style={{ marginTop: 15 }} >
        <Col span={19} className="flex-left" >
            <b>Buy from stock</b>
        </Col>
        <Col span={5} style={{ paddingLeft: 8 }}>
            <Checkbox
              disabled={disableBuyFromStock}
              defaultChecked={disableBuyFromStock}
              onChange={changeBuyFromStock}
              className="fr"
            />
        </Col>
      </Row> }

    </Modal>
  );
}

OrderKeysModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  setNumber: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  maxPrice: PropTypes.number,
  game: PropTypes.string.isRequired,
  region: PropTypes.string.isRequired,
  orderID: PropTypes.string,
  pltId: PropTypes.number.isRequired,
  immediate: PropTypes.bool.isRequired,
  changeOrderID: PropTypes.func.isRequired,
  changeImmediate: PropTypes.func.isRequired,
  buyFromStock: PropTypes.bool,
  changeBuyFromStock: PropTypes.func,
  buyFromStockSetting: PropTypes.bool,
  stockAmount: PropTypes.number.isRequired,
};

OrderKeysModal.defaultProps = {
  maxPrice: 0,
  orderID: '',
};

export default OrderKeysModal;
