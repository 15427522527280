/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import ListWrapper from '../../HOC/ListWrapper';
import {
  blizzardAllListFetchReset,
  blizzardFetchPriceList,
  blizzardFetchRegions,
  blizzardOrderGames,
  blizzardOrderGamesReset,
  blizzardSearchGamesReset,
  blizzardGetQuantityOnStock,
  blizzardFetchPriceListNonMgr,
  blizzardOrderGamesNonMgr
} from '../../Reducers/blizzard';
import TableGameList from '../Price/TableGameList';
import UserSettingsService from '../../Services/UserSettingsService';
import './styles.scss';
import useTable from '../../Utils/useTable';
import { useOutletContext } from 'react-router-dom';

/** This is Page */
function BlizzardPrice() {

  const isManager = useOutletContext();
  const [priceList, setPriceList] = useState("");

  const {
    list,
    pagination,
    onChange: parentOnChange,
    onFilter,
    isLoading,
    fetch: fetchList,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.blizzard[priceList], priceList === 'priceList' ? blizzardFetchPriceList : priceList === 'price_list_account' ? blizzardFetchPriceListNonMgr : null);

  const dispatch = useDispatch();

  const onChange = (a, b, c) =>
    UserSettingsService.onChangeSettings('BlizzardPrice', parentOnChange, a, b, c);

  useEffect(() => {
    if (isManager !== null) {
    if (isManager) {
      setPriceList("priceList");
    } else {
      setPriceList("price_list_account");
    }
  }
  }, [isManager])

  const payload = useSelector(
    (state) => state.core.customer.id,
    shallowEqual
  );

  const updateList = useCallback(
    () => fetchList(),
    [dispatch, fetchList]
  );

  useEffect(() => {
    async function update() {
      const response = await updateList();
    }
    update()
  }, [payload]);


  return (
    <>
      <Typography.Title>Blizzard Price</Typography.Title>
      <Row gutter={16}>
        <Col span={24}>
          <TableGameList
            isBlizzard={true}
            list={list.list}
            isLoading={isLoading}
            pagination={pagination}
            selections
            onChange={(a, b, c) => onChange(a, b, c)}
            // sorter={sorter}
            stateReducer={(state) => state.blizzard}
            fetchRegions={blizzardFetchRegions}
            orderGames={blizzardOrderGames}
            orderGamesNonMgr={blizzardOrderGamesNonMgr}
            orderGamesReset={blizzardOrderGamesReset}
            getQuantityOnStock={blizzardGetQuantityOnStock}
            isUserAllowed={isManager}
          />
        </Col>
      </Row>
    </>
  );
}

export default BlizzardPrice;
